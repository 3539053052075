import { useParams, useNavigate } from "react-router-dom";
import { useGetBoltingDataById } from "../../apis/useGetBoltingData";
import { RowViewComponent } from "./rowViewComponent";
import { useStepperContext } from "../../providers/StepperProvider";
import { useEffect } from "react";
import { DesignItProgress } from "@design-it/react-library";
import { PageContent } from "../../components/PageContent/PageContent";
import { Box } from "@mui/material";

type BoltingDataDetailPageProps = {
  modelExists?: boolean;
};

export const BoltingDataDetailPage = ({
  modelExists = true,
}: BoltingDataDetailPageProps) => {
  const { id } = useParams();
  const { data, refetch, isLoading } = useGetBoltingDataById(id);
  const navigate = useNavigate();

  const { setShowStepper } = useStepperContext();

  useEffect(() => {
    setShowStepper(false);
  }, [setShowStepper]);

  return (
    <PageContent
      showButtonBar={true}
      backButton={{ handler: () => navigate(-1) }}
      nextButton={{ hide: true }}>
      {isLoading ? (
        <Box 
          className="w-full h-full flex justify-center items-center flex-col"
          data-testid="loading"
        >
          <DesignItProgress indeterminate />
        </Box>
      ) : (
        <Box className="h-full" data-testid="content">
          <RowViewComponent
            onClose={() => navigate(-1)}
            updateBoltingOperationDataWithFeedback={() => refetch()}
            modelExists={Boolean(modelExists)}
            selectedRow={data}
          />
        </Box>
      )}
    </PageContent>
  );
};
