import { FC, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useStepperContext } from "../../providers/StepperProvider";
import { BrainIcon } from "../../components/BrainIcon/BrainIcon";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { PageContent } from "../../components/PageContent/PageContent";
import { tertiaryColors } from "../../theme/DarkTheme";

const firstBoxContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  gap: "40px"
};

const analysisBoxContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "40px",
  borderRadius: "8px",
  padding: "60px 32px",
  backgroundColor: tertiaryColors.dark
};

export const StartPage: FC = () => {
  const navigate = useNavigate();
  const { setShowStepper } = useStepperContext();

  useEffect(() => {
    setShowStepper(false);
  }, []);

  return (
    <PageContent
      showButtonBar={true}
      title="How can we help you today?"
      nextButton={{
        hide: true,
      }}
      backButton={{
        hide: true,
      }}
    >
      <Box sx={firstBoxContainer}>
        <Box sx={analysisBoxContainer}>
          <SearchIcon  style={{ fontSize: "60px" }} />
          <Button
            variant="outlined"
            onClick={() =>  console.info("navigate")}>
            Analysis for a known error
          </Button>
        </Box>

        <Box sx={analysisBoxContainer}>
          <BrainIcon />
          <Button
            variant="contained"
            onClick={() => navigate("/analysis")}
          >
            Analysis for an unknown error
          </Button>
        </Box>
      </Box>
    </PageContent >
  );
};
