import Cookies from "js-cookie";

const setCookie = (name: string, value: string, days: number) => {
  Cookies.set(name, value, { expires: days });
};

const removeCookie = (name: string) => {
  Cookies.remove(name);
};

export { setCookie, removeCookie };
