import { getCurrentUser } from "@aws-amplify/auth";
import { apiHelper } from "../apis/apiHelper";
import { getPollProgress } from "../apis/useGetPollProgress";

type PollingFunction = (jobId: string) => Promise<number>;

export function pollFunction(
  fn: PollingFunction,
  interval: number = 1000, // Interval in milliseconds
  timeout: number = 30000 // Timeout in milliseconds
): Promise<void> {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    const jobId = localStorage.getItem("jobId") as string;
    const checkProgress = async () => {
      try {
        const progress = await fn(jobId);

        if (progress === 1) {
          resolve(); // Resolve the promise when progress reaches 1
        } else if (Date.now() - startTime > timeout) {
          reject(new Error("Polling timed out"));
        } else {
          setTimeout(checkProgress, interval);
        }
      } catch (error) {
        reject(error); // Reject the promise on function error
      }
    };

    checkProgress();
  });
}

export async function downloadPdf() {
  const userId = (await getCurrentUser())["userId"];
  const bucketName =
    process.env.REACT_APP_UPLOAD_BUCKET_NAME ?? "raw-upload-bucket";
  const jobId = localStorage.getItem("jobId");

  const response = await apiHelper.post(
    process.env.REACT_APP_REST_API_ROOT_URL + "/get-pdf",
    {
      "user-id": userId,
      "bucket-name": bucketName,
      "job-id": jobId,
    }
  );

  if (response.statusCode !== 200) {
    alert("Failed to trigger downloading PDF");
    return;
  }

  const responseBody = response.body as { pdf_url: string };
  const presignedUrl = responseBody["pdf_url"];

  const milliseconds = 1000;
  const seconds = 300;
  const timeout = milliseconds * seconds;
  const pollingRate = 1000; // milliseconds

  pollFunction(getPollProgress, pollingRate, timeout)
    .then(async () => {
      const presignedUrlResponse = await fetch(presignedUrl);

      if (presignedUrlResponse["status"] !== 200) {
        alert("Failed to download PDF");
        return;
      }

      const blob = await presignedUrlResponse.blob();

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `bolt_it_report_${new Date().toISOString()}.pdf`;
      link.click();
    })
    .catch(error => console.error("Error:", error.message));
}
