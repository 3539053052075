// import { DesignItButton } from "@design-it/react-library";
import { Autocomplete, Box, Button, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { CLIPBOARD_TEXT, TOOLTIP_TEXT } from "./analysis.constants";
import { useStepperContext } from "../../providers/StepperProvider";
import { Navigate } from "react-router-dom";
import { UploadDropContainer } from "../../components/uploadDropContainer/uploadDropContainer";
import { TabProps } from "./types";
import { useCSVContext } from "../../providers/CSVProvider";
import { useModelConfigs } from "./UseModelConfigs";
import { Option } from "./AnalysisHelpers";
import { CopyToClipboard } from "../../components/CopyToClipboard/CopyToClipboard";

const PageContainerBoxStyle ={
  width: "100%", 
  display: "flex"
};

const LeftTabBoxStyle = {
  display:"flex", 
  flexDirection:"column", 
  flexBasis: "50%", 
};

const AutoCompleteBoxStyle = {
  display:"flex", 
  flexDirection: "column", 
  marginTop: '1px', 
  paddingRight: "25px"
};

const RightTabBoxStyle = {
  display:"flex",
  flexDirection:"column", 
  flexBasis: "50%" 
};

const FormatTypographyStyle = {
  display: "flex",
  paddingLeft: "32px", 
  gap: 1 
};

const ValidationButtonStyle = {
  position: 'absolute', 
  bottom: '18px', 
  right: '32px', 
  width: '240px' 
};

export const getPids = (options: Option[], selectedToolId: string) => {
  return selectedToolId
    ? options
      .filter(option => option.tool_id === selectedToolId)
      .map(option => option.process_id)
    : [];
};

export const getAnalysisNames = (options: Option[], selectedPid: string) => {
  return selectedPid
    ? options
      .filter(option => option.process_id === selectedPid)
      .map(option => option.name)
    : [];
};

export const getTorques = (options: Option[], selectedAnalysisName: string) => {
  return selectedAnalysisName
    ? options
      .filter(o => o.name === selectedAnalysisName)
      .map(o => o.target_torque)
    : [];
};

export const getAngles = (
  options: Option[],
  selectedAnalysisName: string,
  selectedTorque: string
) => {
  return selectedTorque
    ? options
      .filter(o => o.target_angle)
      .filter(o => o.name === selectedAnalysisName)
      .map(o => o.target_angle)
    : [];
};

export const ExecuteAnalysis: React.FC<TabProps> = ({
  uploadContainerProps,
  ...props
}) => {
  const { setStep, setShowStepper } = useStepperContext();
  const { progress, setProgress, fileName, lines } = useCSVContext();

  useEffect(() => {
    setStep(1);
    setShowStepper(true);
    localStorage.removeItem("fileLines");
    localStorage.removeItem("lines");
  }, []);

  const { loading, options, toolIds } = useModelConfigs(props.modelConfigs);

  const [selectedToolId, setSelectedToolId] = useState<string | null>(null);
  const [selectedPid, setSelectedPid] = useState<string | null>(null);
  const [selectedAnalysisName, setSelectedAnalysisName] = useState<
    string | null
  >(null);
  const [selectedTorque, setSelectedTorque] = useState<string | null>(null);
  const [selectedAngles, setSelectedAngles] = useState<string | null>(null);

  const pids = useMemo(
    () => getPids(options, selectedToolId || ""),
    [selectedToolId, options]
  );

  const analysisNames = useMemo(
    () => getAnalysisNames(options, selectedPid || ""),
    [selectedPid, options]
  );

  const torques = useMemo(
    () => getTorques(options, selectedAnalysisName || ""),
    [options, selectedAnalysisName]
  );

  const angles = useMemo(
    () => getAngles(options, selectedAnalysisName || "", selectedTorque || ""),
    [options, selectedTorque, selectedAnalysisName]
  );

  useEffect(() => {
    setSelectedPid(null);
    setSelectedAnalysisName(null);
    setSelectedTorque(null);
    setSelectedAngles(null);
  }, [selectedToolId]);

  useEffect(() => {
    setSelectedAnalysisName(null);
    setSelectedTorque(null);
    setSelectedAngles(null);
  }, [selectedPid]);

  useEffect(() => {
    setSelectedTorque(null);
    setSelectedAngles(null);
  }, [selectedAnalysisName]);

  useEffect(() => {
    setSelectedAngles(null);
  }, [selectedTorque]);

  const buttonDisabled = !selectedToolId ||
    !selectedAnalysisName ||
    !selectedPid ||
    !selectedTorque ||
    !props.file;

  const onHandleUploadClick = () => {
    if (!buttonDisabled) {
      props.handleUpload();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (progress === 100 && lines.length > 0) {
    setProgress(0);
    return (
      <Navigate
        to={`/data-check?fileName=${fileName}&configName=${selectedAnalysisName}`}
      />
    );
  }

  return (
    <Box sx={PageContainerBoxStyle}>
      <Box sx={LeftTabBoxStyle}>
        <Typography>1. Choose an analysis job</Typography>

        <Box sx={AutoCompleteBoxStyle}>
          <Autocomplete
            options={toolIds}
            value={selectedToolId}
            onChange={(_, value) => setSelectedToolId(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Tool ID'}
                sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
              />
            )}
          />

          <Autocomplete
            options={pids}
            value={selectedPid}
            disabled={!selectedToolId}
            onChange={(_, value) => setSelectedPid(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Process ID'}
                sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
              />
            )}
          />

          <Autocomplete
            options={analysisNames}
            disabled={!selectedPid || !selectedToolId}
            value={selectedAnalysisName}
            onChange={(_, value) => setSelectedAnalysisName(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Name your analysis job'}
                sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
              />
            )}
          />
          <Autocomplete
            options={torques}
            disabled={
              !selectedAnalysisName ||
              !selectedPid ||
              !selectedToolId
            }
            value={selectedTorque}
            onChange={(_, value) => setSelectedTorque(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Target Torque'}
                sx={{ "& .MuiOutlinedInput-root": { padding: "0" } }}
              />
            )}
          />

          <Autocomplete
            options={angles}
            disabled={
              !selectedTorque ||
              !selectedAnalysisName ||
              !selectedPid ||
              !selectedToolId
            }
            value={selectedAngles}
            onChange={(_, value) => setSelectedAngles(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={'Target Angle'}
                sx={{ "& .MuiOutlinedInput-root": { padding: "0" }}}
              />
            )}
          />
          <Typography className="pl-4">Optional</Typography>
        </Box>
      </Box>

      <Box sx={RightTabBoxStyle}>
        <Typography sx={FormatTypographyStyle}>
          2. Upload an analysis file. Use this 

          <CopyToClipboard
            className="inline"
            text={CLIPBOARD_TEXT}
            label={"format "}
          />

          <Tooltip title={TOOLTIP_TEXT}>
            <HelpOutlineIcon fontSize={"small"} />
          </Tooltip>
        </Typography>

        <UploadDropContainer {...uploadContainerProps} />
      </Box>

      <Button
        variant="contained"
        sx={ValidationButtonStyle}
        onClick={() => onHandleUploadClick()}
        disabled={buttonDisabled}
      >
        Validate
      </Button>
    </Box>
  );
};
