import { Box, Button, FormControl, Input, InputLabel, Tooltip, Typography, } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { CLIPBOARD_TEXT, TOOLTIP_TEXT } from "./analysis.constants";
import { useEffect } from "react";
import { useStepperContext } from "../../providers/StepperProvider";
import { Navigate } from "react-router-dom";
import { UploadDropContainer } from "../../components/uploadDropContainer/uploadDropContainer";
import { TabProps } from "./types";
import { createModelConfig as createModelConfigAPI } from "../upload/uploadHelpers";
import { useCSVContext } from "../../providers/CSVProvider";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import { PrepareAnalysisSchema, prepareAnalysisSchema } from "./validations";
import { errorColors, tertiaryColors } from "../../theme/DarkTheme";
import { CopyToClipboard } from "../../components/CopyToClipboard/CopyToClipboard";

const PageContainerBoxStyle ={
  width: "100%", 
  display: "flex"
};

const LeftTabBoxStyle = {
  display:"flex", 
  flexDirection:"column", 
  flexBasis: "50%", 
  paddingRight: "25px" 
};

const RightTabBoxStyle = {
  display:"flex",
  flexDirection:"column", 
  flexBasis: "50%" 
};

const FormatTypographyStyle = {
  display: "flex",
  paddingLeft: "32px", 
  gap: 1 
};

const ValidationButtonStyle = {
  position: 'absolute', 
  bottom: '18px', 
  right: '32px', 
  width: '240px' 
};

export const PrepareAnalysis: React.FC<TabProps> = ({
  uploadContainerProps,
  targetAngle,
  setToolId,
  setConfigName,
  setProcessId,
  setTargetTorque,
  setTargetAngle,
  ...props
}) => {
  const { setStep, setShowStepper } = useStepperContext();

  const { progress, setProgress, fileName, lines } = useCSVContext();
  const {
    control,
    watch,
    formState,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: zodResolver(
      prepareAnalysisSchema(props.modelConfigs)
    ),
    defaultValues: {
      toolId: '',
      processId: '',
      configName: '',
      targetTorque: ''
    }
  });

  useEffect(() => {
    setStep(1);
    setShowStepper(false);
  }, []);

  const values = watch();

  const createModelConfig = () => {
    createModelConfigAPI({
      tool_id: values.toolId,
      model_name: values.configName,
      process_id: values.processId,
      target_torque: values.targetTorque,
      target_angle: targetAngle,
    });
  };

  const disableButton =
    !values.configName ||
    !values.processId ||
    !values.targetTorque ||
    !values.toolId ||
    !props.file;

  const onSubmit = (data: PrepareAnalysisSchema) => {
    if (!disableButton) {
      setToolId(data.toolId);
      setConfigName(data.configName);
      setProcessId(data.processId);
      setTargetTorque(data.targetTorque);

      createModelConfig();
      props.handleUpload();
    }
  };

  if (progress === 100 && lines.length > 0) {
    setProgress(0);

    return (
      <Navigate
        to={`/data-check?fileName=${fileName}&configName=${values.configName}`}
      />
    );
  }

  return (
    <>
      <Box sx={PageContainerBoxStyle}>
        <Box sx={LeftTabBoxStyle}>
          <Box sx={{height: "100%"}}>
            <Typography>1. Define your analysis job.</Typography>

            <Box>
              <FormControl fullWidth>
                <InputLabel
                  sx={{ color: formState.errors.toolId ? errorColors.main : tertiaryColors.main }}
                  htmlFor="ToolID"
                >
                  Tool ID
                </InputLabel>

                <Controller
                  control={control}
                  name="toolId"
                  render={({ field }) => (
                    <Input
                      {...field}
                      inputProps={{ "data-testid": "toolId" }}
                      error={Boolean(formState.errors.toolId)}
                    />
                  )}
                />

                {Boolean(formState.errors.toolId) && (
                  <Box className="h-[30px]" sx={{ textOverflow: 'ellipsis' }}>

                    <Typography
                      color={"error.main"}
                      className="text-xs"
                      data-testid="toolId-error"
                    >
                      {formState.errors.toolId?.message}
                    </Typography>
                  </Box>
                )}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel
                  sx={{ color: formState.errors.processId ? errorColors.main : tertiaryColors.main }}
                  htmlFor="ProcessID"
                >
                  Process ID
                </InputLabel>

                <Controller
                  control={control}
                  name="processId"
                  render={({ field }) => (
                    <Input
                      {...field}
                      error={Boolean(formState.errors.processId)}
                      inputProps={{ "data-testid": "processId" }}
                    />
                  )}
                />

                {Boolean(formState.errors.processId) && (
                  <Box className="h-[30px]">
                    <Typography
                      className="text-red-600 text-xs"
                      data-testid="processId-error"
                    >
                      {formState.errors.processId?.message}
                    </Typography>
                  </Box>
                )}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel
                  sx={{ color: formState.errors.configName ? errorColors.main : tertiaryColors.main }}
                  htmlFor="ConfigName"
                >
                  Name your analysis job
                </InputLabel>

                <Controller
                  control={control}
                  name="configName"
                  render={({ field }) => (
                    <Input
                      {...field}
                      error={Boolean(formState.errors.configName)}
                      inputProps={{ "data-testid": "configName" }}
                    />
                  )}
                />

                {Boolean(formState.errors.configName) && (
                  <Box className="h-[30px]">
                    <Typography
                      className="text-red-600 text-xs"
                      data-testid="configName-error"
                    >
                      {formState.errors.configName?.message}
                    </Typography>
                  </Box>
                )}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel
                  htmlFor="targetTorque"
                  sx={{ color: formState.errors.targetTorque ? errorColors.main : tertiaryColors.main }}
                >
                  Target Torque
                </InputLabel>

                <Controller
                  control={control}
                  name="targetTorque"
                  render={({ field }) => (
                    <Input
                      {...field}
                      error={Boolean(formState.errors.targetTorque)}
                      inputProps={{ "data-testid": "targetTorque" }}
                    />
                  )}
                />

                {Boolean(formState.errors.targetTorque) && (
                  <Typography
                    className="text-red-600 text-xs"
                    data-testid="targetTorque-error"
                  >
                    {formState.errors.targetTorque?.message}
                  </Typography>
                )}
              </FormControl>

              <FormControl fullWidth>
                <InputLabel htmlFor="targetAngle">
                  Target Angle
                </InputLabel>
                <Input
                  value={targetAngle}
                  onChange={(e) => setTargetAngle(e.target.value)}
                  fullWidth
                  placeholder="Enter Target Angle"
                  disabled={
                    !values.configName ||
                    !values.processId ||
                    !values.targetTorque ||
                    !values.toolId
                  }
                />
                <Typography className="pt-2 pl-4">Optional</Typography>
              </FormControl>
            </Box>
          </Box>
        </Box>

        <Box sx={RightTabBoxStyle} >
          <Typography sx={FormatTypographyStyle} >
            2. Upload a training file. Use this

            <CopyToClipboard
              className="inline"
              text={CLIPBOARD_TEXT}
              label={"format "}
            />

            <Tooltip title={TOOLTIP_TEXT}>
              <HelpOutlineIcon fontSize={"small"} />
            </Tooltip>
          </Typography>

          <UploadDropContainer {...uploadContainerProps} />
        </Box>
      </Box >
      <Button
        variant="contained"
        sx={ValidationButtonStyle}
        onClick={handleSubmit(onSubmit)}
        disabled={disableButton}
      >
        Validate
      </Button>
    </>
  );
};
