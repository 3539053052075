import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export type TabData = {
  label: string;
  value: string;
  content: React.ReactNode;
  disabled?: boolean;
  dataTestId?: string;
};

export type TabsContentProps = {
  tabs: TabData[];
  onChange?: (newValue: string) => void;
  defaultValue?: string;
};

export const TabsContent: React.FC<TabsContentProps> = ({
  tabs,
  onChange,
  defaultValue
}) => {
  const [value, setValue] = React.useState<string>(
    defaultValue ?? tabs[0]?.value ?? ""
  );

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    onChange?.(newValue);
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            disabled={tab.disabled}
            data-testid={tab.dataTestId}
          />
        ))}
      </Tabs>
      
      <Box sx={{marginTop: "32px"}}>
        {tabs.map((tab) => (
          value === tab.value && (
            <Box key={tab.value}>
              {tab.content}
            </Box>
          )
        ))}
      </Box>
    </Box>
  );
};
