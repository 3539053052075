import { getCurrentUser } from "@aws-amplify/auth";
import { apiHelper } from "../../apis/apiHelper";

function columnsExistInDf(df, idColumns) {
  return idColumns.every(str => df.listColumns().includes(str.value));
}

export async function getUploadedFileConfigColumnNames() {
  try {
    const user_id = (await getCurrentUser())["userId"];

    const url =
      process.env.REACT_APP_REST_API_ROOT_URL +
      "/configurations/get-column-names";

    const resp = await apiHelper.post(url, {
      user_id,
    });
    return resp["column_names"];
  } catch (error) {
    console.log("Error: ", error);
  }
}

export async function verifyCorrectStructure(df) {
  const failingChecks = [];
  const body = await getUploadedFileConfigColumnNames();
  Object.entries(body).forEach(([, value]) => {
    if (!columnsExistInDf(df, [{ value }])) {
      failingChecks.push(`${value}`);
    }
  });
  // check for each element to be true with reduce funcitonality
  return {
    result: failingChecks.length === 0,
    failingChecks: failingChecks,
  };
}
