import { Box, Button, Modal, Typography } from "@mui/material";
import Lottie from 'react-lottie';
import animationData from "../../lotties/Brain-Master-1.json";
import { useGetModelAlreadyExists } from "../../apis/useGetModelAlreadyExists";
import { useGetPollProgress } from "../../apis/useGetPollProgress";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { queryClient } from "../../providers/ReactQueryProvider";
import "./style.scss";
import { tertiaryColors } from "../../theme/DarkTheme";

const ModalStyle = {
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  margin: "0px auto"
};

const ModalBoxStyle = {
  bgcolor: tertiaryColors.dark,
  // gap: 4,
  borderRadius: "24px",
  width: "440px",
  height: "440px",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  flexDirection: "column"
};

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSetings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

export type DataCheckModalProps = {
  open: boolean;
  fileName: string;
  configName: string;
  jobId: string;
  handleClose: () => void;
};

export const DataCheckModal = ({
  open,
  handleClose,
  jobId,
  fileName,
  configName
}: DataCheckModalProps) => {
  const { data: modelExists } = useGetModelAlreadyExists(configName);
  const { data: pollProgress } = useGetPollProgress(jobId);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: ['use-get-poll-progress'] });
    };
  }, []);

  if (pollProgress === 1) {
    if (modelExists) {
      return (
        <Navigate
          to={`/results?fileName=${fileName}&configName=${configName}`}
        />
      );
    }

    return (
      <Navigate
        to={`/labelling?fileName=${fileName}&configName=${configName}`}
      />
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className='flex justify-center items-center data-check-modal'
    >
      <Box sx={ModalStyle}>
        <Box sx={ModalBoxStyle}>
          <Typography
            variant="h5"
            component="div"
            fontWeight={50}
          >
            {modelExists && (
              <span data-testid="model-exists-title-evaluation">Evaluation</span>
            )}
            {!modelExists && (
              <span data-testid="model-exists-title-training">Training</span>
            )}
          </Typography>

          <Typography
            component="h6"
            fontWeight={100}
          >
            {modelExists && (
              <span data-testid="model-exists-subtitle-results">Results</span>
            )}
            {!modelExists && (
              <span data-testid="model-exists-subtitle-predictions">Predictions</span>
            )} will be available shortly
          </Typography>

          <Lottie
            options={lottieOptions}
            height={300}
            width={300}
          />

          <Button
            variant="text"
            onClick={handleClose}
          >
            Cancel progress
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
