import LoadingScreenProgressBar from "../loadingScreen/progressBar";
import "./style.scss";
import { memo } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { tertiaryColors } from "../../theme/DarkTheme";

const ModalStyle = {
  display: 'flex',
  justifyContent: "center",
  alignItems: "center"
};

const ModalBoxStyle = {
  bgcolor: tertiaryColors.dark,
  gap: "12px",
  padding: "16px 0",
  borderRadius: "24px",
  width: "440px",
  height: "440px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
};

type UploadDialogProps = {
  uploadProgress: number;
  handleCancelUpload: () => void;
};

function UploadDialog({
  uploadProgress,
  handleCancelUpload
}: UploadDialogProps) {
  const innerCancelCallback = () => {
    if (uploadProgress !== 100) {
      handleCancelUpload();
    }
  };

  return (
    <Modal
      open={true}
      onClose={innerCancelCallback}
      sx={ModalStyle}
    >
      <Box sx={ModalBoxStyle}>
        <Typography
          variant="h5"
          component="div"
          fontWeight={50}
        >
          Your file is uploading
        </Typography>

        <LoadingScreenProgressBar value={uploadProgress / 100} />

        <Button
          variant="text"
          onClick={innerCancelCallback}
        >
          Cancel Progress
        </Button>
      </Box>
    </Modal>
  );
}

export default memo(UploadDialog);
