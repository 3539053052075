import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ChartDataPoint {
	x: number;
	y: number;
}

interface ChartDataSet {
	label: string;
	data: ChartDataPoint[];
	borderColor?: string;
	backgroundColor?: string;
	borderWidth: number;
	yAxisID?: string;
}

export interface LineChartConfig {
	labels: number[];
	datasets: ChartDataSet[];
	xTitle: string | "";
	yTitle: string | string[] | "";
	title: string;
}

interface LineChartProps {
	config: LineChartConfig;
	width?: number | string;
	height?: number | string;
}

const convertDataPoints = (
  dataPoints: ChartDataPoint[]
): { x: number; y: number }[] => {
  return dataPoints.map(point => ({ x: point.x, y: point.y }));
};

const LineChart: React.FC<LineChartProps> = ({
  config,
}) => {
  const { labels, datasets, xTitle, yTitle, title } = config;

  const data: ChartData<"line"> = {
    labels,
    datasets: datasets.map(dataset => ({
      label: dataset.label,
      data: convertDataPoints(dataset.data),
      borderColor: dataset.borderColor || "rgba(75, 192, 192, 1)",
      backgroundColor:
				dataset.backgroundColor || "rgba(75, 192, 192, 0.2)",
      borderWidth: dataset.borderWidth,
      pointStyle: false,
      yAxisID: dataset.yAxisID,
    })),
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: xTitle,
          font: {
            family: "Frutiger LT 45 Light",
            size: 25,
          },
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: Array.isArray(yTitle) ? yTitle[0] : yTitle,
          font: {
            family: "Frutiger LT 45 Light",
            size: 25,
          },
        },
      },
      y1: {
        beginAtZero: false,
        type: "linear",
        position: "right",
        grid: {
          drawOnChartArea: false, // Only want the grid lines for one axis to show up
        },
        title: {
          display: true,
          text:
						Array.isArray(yTitle) && yTitle.length > 1
						  ? yTitle[1]
						  : "",
          font: {
            family: "Frutiger LT 45 Light",
            size: 25,
          },
        },
      },
    },
  };

  return (
    <Line
      data={data}
      options={options}
      // width={"600px"}
      // height={"800px"}
      // plugins={[plugin]}
      // style={{ minHeight: 0, maxHeight: "100%" }}
      // style={{ maxWidth: "100%" }}
    />
  );
};

export default LineChart;
