/* eslint-disable @typescript-eslint/no-explicit-any */

import { AgGridReact, CustomCellRendererProps } from "@ag-grid-community/react";
import {
  GridReadyEvent,
  IDatasource,
  ModuleRegistry,
} from "@ag-grid-community/core";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { DesignItProgress } from "@design-it/react-library";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { getAllBoltingData } from "../../apis/useGetBoltingData";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import { getCurrentUser } from "@aws-amplify/auth";
import { AnomalyTagComponent } from "../../components/AnomalyTag/AnomalyTag";
import "./resultsTable.scss";
import { useCallback, useMemo } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
  ResultsTableProps,
  getResultTableColDefs,
} from "../../components/table/CustomHeader";
ModuleRegistry.registerModules([InfiniteRowModelModule]);

export const ResultsTable = ({
  onArrowClick,
  isLoading,
  className,
  hideArrow = false,
}: ResultsTableProps) => {
  const iconStyle = useMemo(() => {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "22px",
      cursor: "pointer",
    };
  }, []);

  const anomalyCellRenderer = useCallback((props: CustomCellRendererProps) => {
    if (props.value !== undefined) {
      return <AnomalyTagComponent value={props.value} />;
    }
  }, []);

  const arrowCellRenderer = useCallback(
    (props: CustomCellRendererProps) => (
      <ArrowForwardRoundedIcon
        style={iconStyle}
        onClick={() => onArrowClick?.(props.data)}
      />
    ),
    [onArrowClick, iconStyle]
  );

  const idCellRenderer = useCallback((props: ICellRendererParams) => {
    if (props.value !== undefined) {
      return `${props.data.productId}`;
    } else {
      return (
        <DesignItProgress
          style={{ "--md-circular-progress-size": "30px" }}
          indeterminate
        />
      );
    }
  }, []);

  const colDefs = getResultTableColDefs(
    idCellRenderer,
    anomalyCellRenderer,
    arrowCellRenderer,
    hideArrow
  );

  const getRows = async (params: {
    startRow: number;
    endRow: number;
    successCallback: (rowsThisPage: any[], lastRow?: number) => void;
    failCallback: (error: any) => void;
  }) => {
    const { userId } = await getCurrentUser();

    const pageSize = 20;
    const pageNumber = Math.floor(params.startRow / pageSize) + 1;

    const cacheKey = `boltingData_${userId}_${pageNumber}`;
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData) {
      if (cachedData !== "[]") {
        const data = JSON.parse(cachedData);
        let lastRow = undefined;
        if (data.length < pageSize) {
          lastRow = params.startRow + data.length;
        }
        params.successCallback(data, lastRow);
        return;
      }
    }

    getAllBoltingData(
      userId,
      { anomaly_detected: "yes" },
      pageSize,
      pageNumber
    ).then(data => {
      const storageSize = JSON.stringify(localStorage).length;
      const dataSize = JSON.stringify(data).length;

      const remainingSpace = 5242880 - storageSize;

      if (dataSize < remainingSpace) {
        localStorage.setItem(cacheKey, JSON.stringify(data));
      } else {
        console.warn("LocalStorage is full or nearly full. Cannot cache data.");
      }

      let lastRow = undefined;
      if (data.length < pageSize) {
        lastRow = params.startRow + data.length;
      }

      params.successCallback(data, lastRow);
    });
  };

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    const dataSource: IDatasource = {
      rowCount: undefined,
      getRows: getRows,
    };
    params.api.setGridOption("datasource", dataSource);
  }, []);

  return (
    <div className="result-layout h-full">
      <div className={`table-layout ${className}`}>
        <AgGridReact
          loading={isLoading}
          columnDefs={colDefs}
          rowClass="table-row"
          rowBuffer={3}
          rowModelType={"infinite"}
          cacheBlockSize={20}
          cacheOverflowSize={10}
          maxConcurrentDatasourceRequests={5}
          infiniteInitialRowCount={1000}
          maxBlocksInCache={200}
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
};
