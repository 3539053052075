import React from "react";
import { apiHelper } from "../../apis/apiHelper";
import { BoltingData } from "../../apis/types";
import { FeedbackValues } from "../../components/table/CustomHeader";
import { Box, Button } from "@mui/material";
import { endpoints } from "../../apis/endpoints";

interface feedbackComponentInput {
  row: BoltingData;
  updateBoltingOperationDataWithFeedback: (
    row: BoltingData,
    feedback: string
  ) => void;
  updateSelectedRow: (row: BoltingData) => void;
}

export function FeedbackComponent(props: feedbackComponentInput) {
  const registerFeedBack = async (feedback: string) => {
    try {
      const response = await apiHelper.patch(
        endpoints.UPDATE_BOLTING_DATA_FEEDBACK,
        {
          PartitionKey: props.row["productId#processId"],
          SortKey: props.row.createdDate,
          Feedback: feedback,
        }
      );

      if (!response["Attributes"]) {
        throw Error("Setting feedback failed");
      }

      const localData = JSON.stringify(localStorage);
      const localDataParsed = JSON.parse(localData);

      // find row keys in localdataparsed that begin with boltingData
      const boltingDataRowsKeys = Object.keys(localDataParsed).filter(row =>
        row.includes("boltingData")
      );

      for (const element of boltingDataRowsKeys) {
        const page = JSON.parse(localDataParsed[element]);
        const row = page.find((row: BoltingData) => row.id === props.row.id);
        if (row) {
          row.feedback = feedback;
          localStorage.setItem(element, JSON.stringify(page));
          break;
        }
      }

      props.updateBoltingOperationDataWithFeedback(props.row, feedback);
      props.updateSelectedRow({ ...props.row, feedback: feedback });
    } catch (error) {
      alert("Setting feedback failed");
    }
  };

  return (
    <Box>
      {props.row?.feedback == "OK" || props.row?.feedback == "NOK" ? (
        <span className="body-text-content">Thanks for the feedback!</span>
      ) : (
        <span style={{ fontSize: "18px", display: "flex", padding: "8px 0" }}>
          <span style={{ padding: "4px", whiteSpace: "nowrap" }}>
            Please provide feedback. Is this an anomaly?
          </span>
          <span className="body-button flex flex-row">
            <Button onClick={() => registerFeedBack(FeedbackValues.NOK)}>
              Yes
            </Button>

            <Button onClick={() => registerFeedBack(FeedbackValues.OK)}>
              No
            </Button>
          </span>
        </span>
      )}
    </Box>
  );
}
