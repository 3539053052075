import { Box, Button, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { useMenuContext } from "../../providers/MenuProvider";
import { primaryColors, secondaryColors } from "../../theme/DarkTheme";

const IconButtonStyle = {
  borderRadius: "10px", 
  bgcolor: primaryColors.main, 
  cursor: "pointer", 
  ":hover" : { bgcolor:primaryColors.dark }
};

export const MenuBar = () => {
  const { isCollapsed } = useMenuContext();
  const containerWidth = isCollapsed ? "64px" : "300px";
  const navigate = useNavigate();
  
  return (
    <Box
      sx={{ padding: "18px 12px", width: containerWidth }}
      data-testid="side-menu"
    >
      {isCollapsed ? (
          <IconButton onClick={() => navigate("/start")}  sx={IconButtonStyle}>
            <DriveFolderUploadOutlinedIcon  sx={{ color: secondaryColors.contrastText, }} />
          </IconButton>
      ) : (
        <Button
          variant="contained"
          onClick={() => navigate("/start")}
          sx={{ justifyContent: "flex-start" }}
        >
          <DriveFolderUploadOutlinedIcon sx={{ color: secondaryColors.contrastText }} />
          <Typography>Home</Typography>
        </Button>
      )}
    </Box> 
  );
};