import { AgGridReact } from "@ag-grid-community/react";
import {
  GridReadyEvent,
  IDatasource,
  ModuleRegistry,
} from "@ag-grid-community/core";
import { InfiniteRowModelModule } from "@ag-grid-community/infinite-row-model";
import { getCurrentUser } from "@aws-amplify/auth";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { BoltingData } from "../../apis/types";
import { getAllBoltingData } from "../../apis/useGetBoltingData";

import "../results/resultsTable.scss";
import { useCallback } from "react";
import {
  LabellingTableProps,
  useIdCellRenderer,
  getLabellingTableColDefs,
  FeedbackValues,
} from "../../components/table/CustomHeader";

ModuleRegistry.registerModules([InfiniteRowModelModule]);

export const LabellingTable = ({
  className,
  onArrowClick,
  setLoadedAllData,
  setUnlabelledDataFound,
}: LabellingTableProps) => {
  /**
   * Since something's off with the AgGrid column definitions, we're using any[] here.
   * Need to be refactored to use ColDef[] or ColGroupDef[].
   */
  /* eslint-disable  @typescript-eslint/no-explicit-any */

  const idCellRenderer = useIdCellRenderer();

  const colDefs = getLabellingTableColDefs(idCellRenderer, onArrowClick);

  const getRows = async (params: {
    startRow: number;
    successCallback: (data: BoltingData[], lastRow?: number) => void;
  }) => {
    setLoadedAllData(false);

    const { userId } = await getCurrentUser();

    const pageSize = 8;
    const pageNumber = Math.floor(params.startRow / pageSize) + 1;

    const cacheKey = `boltingData_${userId}_${pageNumber}`;
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData) {
      if (cachedData !== "[]") {
        const data = JSON.parse(cachedData);
        let lastRow = undefined;

        for (const element of data) {
          if (element.feedback === FeedbackValues.NONE) {
            setUnlabelledDataFound(true);
          }
        }

        if (data.length < pageSize) {
          setLoadedAllData(true);
          lastRow = params.startRow + data.length;
        }
        params.successCallback(data, lastRow);
        return;
      }
    }

    getAllBoltingData(userId, {}, pageSize, pageNumber).then(data => {
      const storageSize = JSON.stringify(localStorage).length;
      const dataSize = JSON.stringify(data).length;

      const remainingSpace = 5242880 - storageSize;

      for (const element of data) {
        if (element.feedback === FeedbackValues.NONE) {
          setUnlabelledDataFound(true);
        }
      }

      if (dataSize < remainingSpace) {
        localStorage.setItem(cacheKey, JSON.stringify(data));
      } else {
        console.warn("LocalStorage is full or nearly full. Cannot cache data.");
      }

      let lastRow = undefined;

      if (data.length < pageSize) {
        setLoadedAllData(true);
        lastRow = params.startRow + data.length;
      }

      params.successCallback(data, lastRow);
    });
  };

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    const dataSource: IDatasource = {
      rowCount: undefined,
      getRows: getRows,
    };
    params.api.setGridOption("datasource", dataSource);
  }, []);

  return (
    <div
      className={`table-layout ${className}`}
    >
      <AgGridReact
        columnDefs={colDefs}
        rowClass="table-row"
        rowBuffer={16}
        rowModelType={"infinite"}
        cacheBlockSize={8}
        cacheOverflowSize={32}
        maxConcurrentDatasourceRequests={10}
        infiniteInitialRowCount={100}
        maxBlocksInCache={500}
        onGridReady={onGridReady}
      />
    </div>
  );
};