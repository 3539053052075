import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { AnomalyTagComponent } from "../../components/AnomalyTag/AnomalyTag";
import "../results/resultsTable.scss";
import { useCallback, useMemo } from "react";
import {
  RowViewTableProps,
  useIdCellRenderer,
  getLabellingTableColDefs,
  getResultTableColDefs,
} from "../../components/table/CustomHeader";

export const RowViewTable = ({
  onArrowClick,
  data,
  isLoading,
  className,
  hideArrow = false,
  modelExists,
}: RowViewTableProps) => {
  const idCellRenderer = useIdCellRenderer();

  const iconStyle = useMemo(() => {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "22px",
      cursor: "pointer",
    };
  }, []);

  const arrowCellRenderer = useCallback(
    (props: CustomCellRendererProps) => (
      <ArrowForwardRoundedIcon
        style={iconStyle}
        onClick={() => onArrowClick?.(props.data)}
      />
    ),
    [onArrowClick, iconStyle]
  );

  const tagCellRenderer = useCallback(
    (props: CustomCellRendererProps) => (
      <AnomalyTagComponent value={props.value} />
    ),
    []
  );

  const labellingTableColDefs = getLabellingTableColDefs(
    idCellRenderer,
    onArrowClick
  );
  const resultsTableColDefs = getResultTableColDefs(
    idCellRenderer,
    tagCellRenderer,
    arrowCellRenderer,
    hideArrow
  );

  return (
    <div className={`table-layout ${className}`}>
      <AgGridReact
        rowData={data}
        loading={isLoading}
        columnDefs={modelExists ? resultsTableColDefs : labellingTableColDefs}
        rowClass="table-row"
      />
    </div>
  );
};
