import { DesignItIconButton } from "@design-it/react-library";
import DetailedGraphView from "../../components/graphView/detailedGraphViewComponent";
import { FeedbackComponent } from "../dashboard/feedbackComponent";
import { useResultsContext } from "../../providers/resultsProvider";
import { BoltingData } from "../../apis/types";
import { RowViewTable } from "./rowViewTable";
import { Box } from "@mui/material";

interface RowViewComponentProps {
  onClose: () => void;
  updateBoltingOperationDataWithFeedback: () => void;
  modelExists: boolean;
  selectedRow?: BoltingData | null;
}

export function RowViewComponent({
  onClose,
  modelExists,
  selectedRow,
  ...props
}: Readonly<RowViewComponentProps>) {
  const { currentRow, setCurrentRow } = useResultsContext();
  const rowToUse = selectedRow ?? currentRow;

  if (!rowToUse) {
    return <div>No data available...</div>;
  }

  return (
    <div className="h-full">
      <div className="flex items-center justify-between">
        <div className="flex items-center ">
          {!modelExists && (
            <FeedbackComponent
              row={rowToUse}
              updateBoltingOperationDataWithFeedback={
                props.updateBoltingOperationDataWithFeedback
              }
              updateSelectedRow={setCurrentRow}
            />
          )}
        </div>

        <div className="max-h-fit">
          <DesignItIconButton icon="close" variant="icon" onClick={onClose} />
        </div>
      </div>

      <div className="h-[90%]">
        <Box sx={{bgcolor:""}} className="rounded-xl p-4 h-full">
          <div className="h-[82%]">
            {rowToUse && <DetailedGraphView row={rowToUse} />}
          </div>

          <div className="h-[18%]">
            <RowViewTable
              data={[rowToUse]}
              isLoading={false}
              hideArrow={true}
              modelExists={modelExists}
              className="h-full py-0 pl-16 pr-0 min-h-fit"
            />
          </div>
        </Box>
      </div>
    </div>
  );
}
