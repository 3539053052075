import { useNavigate, useSearchParams } from "react-router-dom";
import { ResultsTable } from "./ResultsTable";
import { useGetBoltingDataWithAnomalies } from "../../apis/useGetBoltingData";
import { useStepperContext } from "../../providers/StepperProvider";
import { DesignItProgress } from "@design-it/react-library";
import { downloadPdf } from "../../utils/reportHelpers";
import {
  MLFlowStepperLabelsAnalysisTab,
  MLFlowStepperLabelsExecuteTab,
} from "../../constants/stepper.constants";
import { Stepper } from "../dashboard/Stepper";
import { PageContent } from "../../components/PageContent/PageContent";
import { Box } from "@mui/material";

export const ResultsPage = () => {
  const { isExecuteTabAnalysis } = useStepperContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fileName = searchParams.get("fileName");

  const { data, isLoading } = useGetBoltingDataWithAnomalies();

  return (
    <PageContent
      title="Results"
      showButtonBar={true}
      nextButton={{
        text: "Download",
        handler: () => downloadPdf()
      }}
      backButton={{
        handler: () => navigate(`/data-check?fileName=${fileName}&lastStep=results`)
      }}
    >

      {isLoading && !data ? (
        <div className="w-full h-full flex justify-center items-center flex-col">
          <DesignItProgress indeterminate />
        </div>
      ) : (
        <>
          <Stepper
            steps={
              isExecuteTabAnalysis
                ? MLFlowStepperLabelsExecuteTab
                : MLFlowStepperLabelsAnalysisTab
            }
            currentStep={3}
            shouldShowStepper={true}
          />{" "}

          <Box className="h-[80%]">
            <ResultsTable
              className="h-full"
              data={data!}
              isLoading={isLoading}
              onArrowClick={row => navigate(`/results/${row.id}`)}
            />
          </Box>
        </>
      )}
    </PageContent>
  );
};
