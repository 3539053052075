import { Box, Typography } from "@mui/material";
import { primaryColors } from "../../theme/DarkTheme";

interface LogoProps {
  clickHandler?: () => void
}

export const BoldItLogo = (props: LogoProps) => {
  return (
    <Box sx={{ display: "flex", cursor:"default" }} onClick={props.clickHandler} >
      <Typography sx={{
        fontFamily: 'Orbitron, sans-serif',
        fontWeight: 'bold',
        fontSize: '22px',
      }}>
        bolt
      </Typography>

      <Typography sx={{
        color: primaryColors.main,
        fontFamily: 'Orbitron, sans-serif',
        fontWeight: 'bold',
        fontSize: '22px',
      }}
      >
        _it
      </Typography>
    </Box>
  );
};